import { createTheme } from "@mui/material/styles"

const theme = createTheme({
  navHeight: 56,
  sectionPadding: "padding-top: 1.5em; padding-bottom: 1.5em;",
  fonts: {
    primary: "Inter, sans-serif",
    secondary: "Oswald, sans-serif",
  },
  layout: {
    contentWidth: 1236,
  },
  palette: {
    primary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#164C2D",
      light: "#F1FBF5",
      dark: "#15372C",
    },
    secondary: {
      // contrastText: "rgba(0, 0, 0, 0.87)",
      main: "#FFD47E",
      light: "#207042",
      dark: "#1A4D13",
    },
    tertiary: {
      main: "#040D08",
      dark: "#222222",
      light: "#FFA800",
    },
    text: {
      primary: "#164C2D",
      secondary: "#FFFFFF",
      content: "#5F5F5F",
      tertiary: "#1A2933",
      disabled: "#10355A",
      hint: "#10355A",
      light: "#FFFFFF",
    },
    alternate: {
      main: "rgb(247, 249, 250)",
      dark: "#e8eaf6",
    },
  },
  typography: {
    fontFamily: `"Inter", "Open Sans", "Arial", sans-serif`,
  },
})

export default theme
